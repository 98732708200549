import attachmentEditPane from './attachment-edit-pane';
import attachmentPane from './attachment-pane';
import xhr from '../xhr-promise';

export default () => {
    const container = document.getElementById('article-attachments');
    const linkArticleAttachments = document.getElementById('link-article-attachments');
    if (!container) return;

    function setAttachmentsCount (count) {
        linkArticleAttachments.textContent = `Attachments (${count})`;
    }

    linkArticleAttachments.addEventListener('click', () => {
        setActivePane('base');
        container.classList.add('is-active');
    });

    function setActivePane (name, options) {
        for (const key of Object.keys(panes)) {
            if (options !== undefined && key === name) {
                panes[key].setup(options);
            }
            panes[key].container.classList.toggle('is-active', key === name);
        }
    }

    const panes = {
        edit: attachmentEditPane(setActivePane),
        base: attachmentPane(setActivePane, setAttachmentsCount)
    };

    // populate list
    xhr('get', `/admin/articles/${container.dataset.articleId}/attachments`)
        .then(({ attachments }) => { panes.base.setup({ attachments }); })
        .catch(() => {});

    const overlay = container.querySelector('.overlay');
    overlay.addEventListener('click', () => {
        container.classList.remove('is-active');
    });
};
