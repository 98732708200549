import detectChanges from './form-detect-changes';
import xhr from './xhr-promise';

export default () => {
    const form = document.getElementById('article-form');
    const edit = document.getElementById('content-editor');
    const prev = document.getElementById('article-preview');
    if (!form || !edit || !prev) return;

    detectChanges(form);

    const titleContainer = document.getElementById('title-container');
    // const title = titleContainer.querySelector('input');

    const articleMeta = document.getElementById('article-meta');
    document.getElementById('link-article-metadata').addEventListener('click', () => {
        articleMeta.classList.add('is-active');
    });
    articleMeta.querySelector('.overlay').addEventListener('click', () => {
        articleMeta.classList.remove('is-active');
    });

    function preview () {
        prev.innerHTML = window.marked(edit.value);
    }

    edit.addEventListener('input', () => {
        preview();
        requestAnimationFrame(sync(edit, prev));
    });

    let _skipSroll = false;

    const sync = (source, target) => () => {
        _skipSroll = true;
        const sourceMaxTop = source.scrollHeight - source.clientHeight;
        const targetMaxTop = target.scrollHeight - target.clientHeight;
        target.scrollTop = source.scrollTop / sourceMaxTop * targetMaxTop;
    };

    prev.addEventListener('scroll', () => {
        if (_skipSroll) { _skipSroll = false; return; }
        requestAnimationFrame(sync(prev, edit));
    });

    edit.addEventListener('scroll', () => {
        if (titleContainer) titleContainer.style.top = -edit.scrollTop + 'px';
        if (_skipSroll) { _skipSroll = false; return; }
        requestAnimationFrame(sync(edit, prev));
    });

    const linkArticleOpen = document.getElementById('link-article-open');
    const savingNotifier = document.getElementById('saving-notifier');
    let _isSaving = false;

    function performSave () {
        if (_isSaving) return;
        _isSaving = true;

        savingNotifier.textContent = '';
        savingNotifier.classList.add('is-active', 'is-animating');

        xhr('put', `/admin/articles/${form.dataset.articleId}`, new FormData(form))
            .then(({ isPublished }) => {
                savingNotifier.classList.remove('is-animating');
                savingNotifier.textContent = (isPublished ? 'Published' : 'Saved');
                linkArticleOpen.classList.toggle('is-hidden', !isPublished);
                setTimeout(() => {
                    savingNotifier.classList.remove('is-active');
                    _isSaving = false;
                }, 1200);
                form.setInitialValues();
            })
            .catch((err) => {
                savingNotifier.classList.remove('is-active');
                _isSaving = false;
                alert(err.message);
            });
    }

    document.getElementById('link-article-save').addEventListener('click', performSave);
    document.addEventListener('keydown', (ev) => {
        if (ev.keyCode == 83 && (navigator.platform.match('Mac') ? ev.metaKey : ev.ctrlKey)) {
            ev.preventDefault();
            performSave();
        }
    }, false);

    window.onbeforeunload = () => { if (form.isChanged()) return 'Unsaved changes'; };

    preview();
};
