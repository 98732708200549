import setRandomClass from './random-class';

export default () => {
    const container = document.getElementById('article');
    if (!container) return;

    function chooseRandomColor () {
        setRandomClass(container);
    }
    chooseRandomColor();

    const title = container.querySelector('.article-title');
    title.addEventListener('click', chooseRandomColor);

    const content = container.querySelector('.article-content');
    content.innerHTML = window.marked(content.dataset.content);
    content.removeAttribute('data-content');

    setTimeout(() => { container.classList.add('is-loaded'); }, 0);
};
