import xhr from '../xhr-promise';

const isImage = (name) => 'gif jpeg jpg png'.split(' ').includes(name.split('.').pop());

export default (setActivePane) => {
    const container = document.getElementById('attachment-edit-pane');
    if (!container) return;

    const form = container.querySelector('form');
    const elements = {
        name: container.querySelector('.field.is-name'),
        content: container.querySelector('.field.is-content'),
        other: container.querySelector('.field.is-other'),
        image: container.querySelector('.markdown.is-image')
    };

    let attachmentId;

    function setup ({ articleId, id, name, content }) {
        attachmentId = id;
        const hasContent = content !== undefined;
        const hasImage = isImage(name);

        elements.name.querySelector('input').value = name;

        if (hasContent) {
            elements.content.querySelector('textarea').value = content;
            elements.content.classList.remove('is-hidden');
        } else {
            elements.content.querySelector('textarea').value = '';
            elements.content.classList.add('is-hidden');
        }

        const img = elements.image.querySelector('img');
        if (hasImage) {
            img.setAttribute('src', `/articles/${articleId}/${name}`);
            img.setAttribute('alt', name);
            elements.image.classList.remove('is-hidden');
        } else {
            img.removeAttribute('src');
            img.removeAttribute('alt');
            elements.image.classList.add('is-hidden');
        }

        if (!hasContent && !hasImage) {
            elements.other.classList.remove('is-hidden');
        } else {
            elements.other.classList.add('is-hidden');
        }
    }

    function performUpdate () {
        form.setAttribute('disabled', true);
        xhr('put', `/admin/attachments/${attachmentId}`, new FormData(form))
            .then(({ attachment }) => {
                setActivePane('base', { attachments: [attachment] });
            })
            .catch(() => {})
            .then(() => { form.setAttribute('disabled', false); });
    }

    form.addEventListener('submit', (ev) => {
        ev.preventDefault();
        performUpdate();
    });

    container.querySelector('.buttons .link').addEventListener('click', () => {
        setActivePane('base');
    });

    return { container, setup };
};
