import initArticleAttachments from './main/article-attachments/index';
import initArticleEdit from './main/article-edit';
import initArticle from './main/article';
import initEmailLink from './main/email-link';
import initKeywords from './main/keywords';
import initProjectHeader from './main/project-header';
import initProjectList from './main/project-list';
import initSidebar from './main/sidebar';

function renderPre (language, value) {
    return `<div class="language-name"><span>${language}</span></div><pre><code class="hljs ${language}">${value}</code></pre>`;
}

function initMarked () {
    const renderer = new window.marked.Renderer();

    renderer.code = (code, language) => {
        try {
            const { value } = window.hljs.highlight(language, code);
            return renderPre(language, value);
        } catch (e) {
            return renderPre('code', code);
        }
    };

    window.marked.setOptions({ renderer });
}

document.addEventListener('DOMContentLoaded', () => {
    initMarked();
    initArticleAttachments();
    initArticleEdit();
    initArticle();
    initEmailLink();
    initKeywords();
    initProjectHeader();
    initProjectList();
    initSidebar();
});
