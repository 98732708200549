export default (method, path, data) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.addEventListener('load', () => {
        if (xhr.status < 400) {
            resolve(xhr.response);
        } else {
            console.log('Request failed', xhr.statusText);
            reject(new Error('Request failed'));
        }
    });
    xhr.addEventListener('error', (err) => {
        console.log('Request failed', err.message);
        reject(new Error('Request failed'));
    });
    xhr.open(method, path);
    xhr.send(data);
});
