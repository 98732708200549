export default () => {
    const container = document.getElementById('project-header');
    if (!container) return;

    const content = container.querySelector('.project-content');
    if (content) {
        content.innerHTML = window.marked(content.dataset.content);
        content.removeAttribute('data-content');
    }

    setTimeout(() => { container.classList.add('is-loaded'); }, 0);
};
