import setRandomClass from './random-class';

export default () => {
    const projectSelect = document.querySelector('.project-select');
    if (!projectSelect) return;

    const nodes = document.querySelectorAll('.project-list .project');

    function createPattern () {
        nodes.forEach(setRandomClass);
    }

    createPattern();
    projectSelect.addEventListener('click', createPattern);
};
