export default () => {
    const sidebar = document.getElementById('sidebar');
    const overlay = document.getElementById('overlay');
    const header = document.getElementById('header');
    if (!header) return;

    function onOpen () {
        sidebar.classList.add('is-active');
        overlay.classList.add('is-active');
    }
    function onClose () {
        sidebar.classList.remove('is-active');
        overlay.classList.remove('is-active');
    }

    const links = sidebar.querySelectorAll('.item');
    for (const link of links) {
        link.addEventListener('click', onClose);
    }

    overlay.addEventListener('click', onClose);
    header.querySelector('.menu').addEventListener('click', onOpen);
};
