export default () => {
    const elements = document.querySelectorAll('.email-link');

    for (const element of elements) {
        element.addEventListener('click', (ev) => {
            ev.preventDefault();
            const href = `mailto:${element.dataset['e0']}@${element.dataset['e1']}`;
            window.location.href = href + '?subject=Hello from your website';
        });
    }
};
