const CLASSES = ['is-blue', 'is-purple', 'is-orange', 'is-green'];

let _lastClass;

function randomClass () {
    let result;

    do {
        result = CLASSES[Math.floor(Math.random() * CLASSES.length)];
    } while (result === _lastClass);

    _lastClass = result;

    return result;
}

export default (node) => {
    node.classList.remove(...CLASSES);
    node.classList.add(randomClass());
};
