import xhr from '../xhr-promise';

export default (setActivePane, setAttachmentsCount) => {
    const container = document.getElementById('attachment-pane');
    if (!container) return;

    const _attachments = [];
    const attachmentList = container.querySelector('.attachment-list');

    function updateAttachmentCount () {
        attachmentList.classList.toggle('is-empty', _attachments.length <= 0);
        setAttachmentsCount(_attachments.length);
    }

    function addAttachments (attachments = []) {
        for (const attachment of _attachments) {
            attachmentList.removeChild(attachment.element);
        }
        for (const attachment of attachments) {
            attachment.element = buildAttachment(attachment);
            const index = _attachments.findIndex(data => data.id === attachment.id);
            if (index > -1) {
                _attachments[index] = attachment;
            } else {
                _attachments.push(attachment);
            }
        }
        _attachments.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
        for (const attachment of _attachments) {
            attachmentList.appendChild(attachment.element);
        }
        updateAttachmentCount();
    }

    function removeAttachment (id) {
        const index = _attachments.findIndex(data => data.id === id);
        if (index > -1) {
            attachmentList.removeChild(_attachments[index].element);
            _attachments.splice(index, 1);
        }
        updateAttachmentCount();
    }

    function getAttachmentHtml (articleId, name) {
        const path = `/articles/${articleId}/${name}`;
        switch (name.split('.').pop()) {
        case 'gif': case 'jpeg': case 'jpg': case 'png':
            return `<img src="${path}" alt="${name}" />`;
        case 'css':
            return `<link rel="stylesheet" href="${path}" />`;
        case 'js':
            return `<script src="${path}"></script>`;
        default:
            return path;
        }
    }
    
    function buildAttachment (data) {
        const element = document.createElement('div');
        element.classList.add('attachment');

        const name = document.createElement('div');
        name.classList.add('name', 'link');
        name.appendChild(document.createTextNode(data.name));
        name.addEventListener('click', () => {
            prompt('Copy', getAttachmentHtml(container.dataset.articleId, data.name));
        });

        const trash = document.createElement('div');
        trash.classList.add('trash', 'link');
        trash.appendChild(document.createTextNode('Delete'));
        trash.addEventListener('click', () => {
            if (!confirm(`Delete ${name.textContent}?`)) return;

            element.classList.add('is-loading');

            xhr('delete', `/admin/attachments/${data.id}`)
                .then(() => { removeAttachment(data.id); })
                .catch(() => { element.classList.remove('is-loading'); });
        });

        const view = document.createElement('div');
        view.classList.add('view', 'link');
        view.appendChild(document.createTextNode('View file'));
        view.addEventListener('click', () => {
            setActivePane('edit', data);
        });

        element.appendChild(trash);
        element.appendChild(view);
        element.appendChild(name);
        return element;
    }
    
    function setup ({ attachments }) {
        addAttachments(attachments);
    }

    const fileInput = container.querySelector('input[type=file]');
    fileInput.addEventListener('change', () => {
        fileInput.setAttribute('disabled', true);

        const formData = new FormData();
        formData.append('file', fileInput.files[0]);

        xhr('post', `/admin/articles/${container.dataset.articleId}/attachments`, formData)
            .then(({ attachment }) => { addAttachments([attachment]); })
            .catch(() => {})
            .then(() => {
                fileInput.value = '';
                fileInput.removeAttribute('disabled');
            });
    });

    return { container, setup };
};
