function getExisting () {
    const parts = window.location.pathname.split('/');
    if (parts[1] === 'keywords') return parts[2].split('+').map(k => k.toLowerCase().trim());
    return [];
}

function toggle (existing, value) {
    const index = existing.indexOf(value);
    if (index > -1) {
        const clone = existing.slice();
        clone.splice(index, 1);
        return clone;
    }
    return existing.concat(value);
}

function getHref (existing, value) {
    const values = toggle(existing, value);
    if (values.length > 0) return '/keywords/' + values.join('+');
    return '/';
}

export default () => {
    const keywords = document.querySelectorAll('.keyword');
    const existing = getExisting();

    const generateLink = (value) => {
        const link = document.createElement('a');
        link.classList.add('keyword');
        if (existing.includes(value)) link.classList.add('is-removal');
        link.setAttribute('href', getHref(existing, value));
        link.appendChild(document.createTextNode(value));
        return link;
    };

    for (let i = 0; i < keywords.length; i++) {
        const keyword = keywords[i];
        keyword.parentElement.replaceChild(generateLink(keyword.textContent.toLowerCase()), keyword);
    }
};
