function getValues (form) {
    const fields = {};

    function addFields (inputs) {
        for (const input of inputs) {
            const key = input.getAttribute('name');
            if (!key) continue;
            fields[key] = input.value;
        }
    }

    addFields(form.getElementsByTagName('input'));
    addFields(form.getElementsByTagName('textarea'));
    addFields(form.getElementsByTagName('select'));

    return fields;
}

export default (form) => {
    let initialValues;

    form.setInitialValues = () => {
        initialValues = getValues(form);
    };

    form.isChanged = () => {
        const values = getValues(form);
        for (const key of Object.keys(initialValues)) {
            if (initialValues[key] !== values[key]) return true;
        }
        return false;
    };

    form.setInitialValues();
};
